/* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: transform 0.2s;
}
.close-icon:hover {
  transform: scale(1.2);
}

/* Heading */
.modal-content h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 0.5px;
  color: #1ca32c;
  margin-bottom: 15px;
}

/* Input Fields */
/* Input Fields */
.modal-content input {
  width: 100%;  /* Ensure full width */
  padding: 12px 10px;
  margin: 10px 0;
  color: #58605b;
  border: none; /* Remove all borders */
  border-bottom: 2px solid #e2e2e2; /* Add only the bottom border */
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
  box-sizing: border-box;  /* Ensure padding and borders are included in the total width */
}

/* Forgot Password Link */
.forgot-password-link {
  color: #1ca32c; /* Green color to match the theme */
  font-size: 14px; /* Slightly smaller font */
  text-decoration: underline; /* Underlined text to mimic a link */
  cursor: pointer; /* Pointer cursor to indicate it's clickable */
  display: inline-block; /* To keep the link inline with other elements */
  margin-top: 10px; /* Add some space above */
  transition: all 0.3s ease; /* Smooth transition on hover */
}

/* Hover effect for Forgot Password Link */
.forgot-password-link:hover {
  color: #148922; /* Darker green on hover */
  text-decoration: none; /* Remove underline on hover */
}

/* Focused Input Fields */
.modal-content input:focus {
  border-bottom: 2px solid #1ca32c; /* Change the bottom border color on focus */
  box-shadow: none; /* Remove any box-shadow effect */
}

/* Placeholder styling */
.modal-content input::placeholder {
  color: #aaa;
}

/* Button */
.modal-content button {
  background-color: #1ca32c;
  color: white;
  padding: 10px 15px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content button:hover {
  background-color: #148922;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.modal-content button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Error Message */
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

/* Password Container */
.password-container {
  position: relative;
  width: 100%;
}

/* Eye Icon */
.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
  color: #1ca32c; /* Green color for the eye icon */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
