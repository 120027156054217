/* Overall container for the JobListPage */
@import url('https://fonts.googleapis.com/css?family=Electrolize');

body {
    font-family: 'Electrolize', sans-serif; /* Apply Electrolize font to the entire page */
    font-size: 16px;                        /* Set a base font size for readability */
    line-height: 1.6;                       /* Increase line height for better readability */
    margin: 0;                              /* Remove default body margin */
    padding: 0;                             /* Remove default body padding */
    background-color: #fff;
}

.job-list-page {
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    background-color: #fff;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;                          /* Reduced padding */
    background-color: #fff; /* Matches the theme color */
    color: #fff;
    height: 50px; /* Set a fixed height (adjust as needed) */
}

.navbar-title {
    font-size: 20px;                           /* Increase font size for prominence */
    font-weight: bold;                         /* Bold font weight */
    color: white;                              /* Text color for default state */
    padding: 6px 20px;                       /* Space around the text for better touch area */
    cursor: pointer;                           /* Pointer cursor for clickability */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
    position: relative;                        /* Relative positioning for pseudo-element */
    border-radius: 3px;                       /* Rounded corners */
}

/* Hover effect for the navbar title */
.navbar-title:hover {
    color: #1ca32c;                            /* Change text color on hover */
    background-color: white;                   /* Background color on hover */
    transform: scale(1.05);                   /* Slightly enlarge the title on hover */
}

.compare-qualifications-button {
    font-size: 14px;                          /* Slightly smaller font size */
    font-weight: bold;                        
    color: white;                             
    padding: 6px 20px;                        /* Reduced padding for a smaller button */
    cursor: pointer;                          
    transition: color 0.3s ease, transform 0.3s ease; 
    position: relative;                       
    border-radius: 5px;                       
    background-color: #1ca32c;                
    border: none;                             
}

/* Hover effect similar to navbar title */
.compare-qualifications-button:hover {
    color: #1ca32c;                           
    background-color: white;                  
    transform: scale(1.05);                   
}

/* Pseudo-element for underline effect */
.compare-qualifications-button::after {
    content: '';                              
    position: absolute;                       
    left: 0;                                  
    right: 0;                                 
    bottom: -4px;                             /* Adjusted for smaller button */
    height: 3px;                              /* Thinner underline */
    background-color: #1ca32c;                
    transform: scaleX(1);                     
    transition: transform 0.3s ease;          
}

/* Hide underline on hover */
.compare-qualifications-button:hover::after {
    transform: scaleX(0);                     
}


/* Pseudo-element for the border effect */
.navbar-title::after {
    content: '';                               /* Empty content for the pseudo-element */
    position: absolute;                        /* Absolute positioning */
    left: 0;                                   /* Align to the left */
    right: 0;                                  /* Align to the right */
    bottom: -5px;                              /* Position slightly below the text */
    height: 4px;                               /* Height of the border */
    background-color: #1ca32c;                 /* Border color in default state */
    transform: scaleX(1);                      /* Start with full width */
    transition: transform 0.3s ease;          /* Smooth transition for border width */
}

/* Hide border on hover */
.navbar-title:hover::after {
    transform: scaleX(0);                      /* Collapse the border width on hover */
}


/* Search Container */
.search-container {
    display: flex;
    gap: 10px; /* Adds space between input fields and buttons */
    align-items: center;
}

.input {
    position: relative; /* For absolute positioning of the label */
    margin: 1rem 0; /* Space between input fields */
}

.input__field {
    color: #666;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    width: 100%; /* Full width */
    padding: 0.75rem; /* Padding inside the input */
    border: 2px solid #ccc; /* Border style */
    border-radius: 4px; /* Rounded corners */
    font-size: 1rem; /* Font size */
    outline: none; /* Remove default outline */
    transition: border-color 0.2s; /* Smooth transition for border color */
}

.input__field:focus {
    border-color: #1ca32c; /* Change border color on focus */
    border-top: none;
    color: #666;
    font-size: 'sans-serif';
 }

.input__label {
    position: absolute; /* Position the label relative to the input */
    top: 0.75rem; /* Initial position */
    left: 0.5rem; /* Align label with input */
    font-size: 1rem; /* Font size */
    color: #aaa; /* Color of the label */
    transition: transform 0.2s, color 0.2s; /* Smooth transition for transformations */
}

/* When input is focused or not empty, move the label */
.input__field:focus + .input__label,
.input__field:not(:placeholder-shown) + .input__label {
    transform: translateY(-1.5rem) scale(0.8); /* Move the label up */
    color: #1ca32c; /* Change label color */
}



.apply-options-container {
    display: flex;                             /* Use Flexbox for horizontal alignment */
    flex-wrap: wrap;                           /* Allow buttons to wrap to a new row if needed */
    gap: 15px;                                 /* Space between buttons */
    margin-top: 15px;                          /* Space above the buttons */
}

.apply_btn {
    padding: 6px 12px;                     /* Reduced padding for smaller buttons */
    font-size: 14px;                       /* Smaller font size for buttons */
    font-weight: bold;                     /* Maintain bold font weight */
    color: white;                          /* Text color */
    background-color: #1ca32c;             /* Button background color */
    border: none;                          /* Remove default border */
    border-radius: 3px;                   /* Slightly rounded corners */
    cursor: pointer;                       /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Hover effect for the apply button */
.apply_btn:hover {
    background-color: #c9f0c9;             /* Darker green on hover */
    color: #666;
    transform: translateY(-2px);           /* Slight lift effect on hover */
}
.apply_btn:active {
    transform: scale(0.95);                   /* Button press effect */
}

.company_name_columns_tile{
    color: #c9f0c9;
}
/* Focus effect for input fields */



.space-btn {
    font-size: 14px !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    color: #007bff !important; /* Green color for the link */
    cursor: pointer !important;
    padding-bottom: 5px !important; /* Adjust for spacing between text and border */
    position: relative !important; /* Set relative positioning for pseudo-element */
}

.space-btn::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important; /* Start with no width */
    height: 2px !important; /* Border height */
    background-color: #007bff !important; /* Green color for the bottom border */
    left: 50% !important; /* Center the border */
    bottom: 0 !important; /* Align the border at the bottom of the link */
    transition: width 0.3s ease, left 0.3s ease !important; /* Smooth transition */
    transform: translateX(-50%) !important; /* Center the border */
}

.space-btn:hover::after {
    width: 100% !important; /* Expand the border width on hover */
    left: 50% !important; /* Maintain centered position */
}

.user-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-email {
    font-size: 16px;
}

.logout-button {
    font-size: 14px !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    color: #007bff !important; /* Green color for the link */
    cursor: pointer !important;
    padding-bottom: 5px !important; /* Adjust for spacing between text and border */
    position: relative !important; /* Set relative positioning for pseudo-element */
}

.logout-button::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important; /* Start with no width */
    height: 2px !important; /* Border height */
    background-color: #007bff !important; /* Green color for the bottom border */
    left: 50% !important; /* Center the border */
    bottom: 0 !important; /* Align the border at the bottom of the link */
    transition: width 0.3s ease, left 0.3s ease !important; /* Smooth transition */
    transform: translateX(-50%) !important; /* Center the border */
}

.logout-button:hover::after {
    width: 100% !important; /* Expand the border width on hover */
    left: 50% !important; /* Maintain centered position */
}

.signin-signup-button {
    font-size: 14px !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    color: #007bff !important; /* Green color for the link */
    cursor: pointer !important;
    padding-bottom: 5px !important; /* Adjust for spacing between text and border */
    position: relative !important; /* Set relative positioning for pseudo-element */
}

.signin-signup-button::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important; /* Start with no width */
    height: 2px !important; /* Border height */
    background-color: #007bff !important; /* Green color for the bottom border */
    left: 50% !important; /* Center the border */
    bottom: 0 !important; /* Align the border at the bottom of the link */
    transition: width 0.3s ease, left 0.3s ease !important; /* Smooth transition */
    transform: translateX(-50%) !important; /* Center the border */
}

.signin-signup-button:hover::after {
    width: 100% !important; /* Expand the border width on hover */
    left: 50% !important; /* Maintain centered position */
}

.button {
    background-color: #1ca32c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #159e2b;
}

/* Job grid layout */
.job-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 30px 40px;
    height: calc(100vh - 80px);
    background-color: #fff;
    overflow: hidden;
}

.job-column {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 100%;
}

.job-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

/* Hover effect for the job item */
.job-item:hover {
    background-color: #e9f5e9;                /* Light green background on hover */
    transform: scale(1.02);                   /* Slightly enlarge the job item on hover */
}

.job-item h3 {
    font-size: 1.4em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.job-item p {
    color: #666;
    margin-bottom: 8px;
    font-size: 1em;
}

/* Details column */
.details-column {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 100%;
}

.job-details {
    color: #333;
}

.job-details h2 {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 15px;
    border-bottom: 2px solid #1ca32c;
}

.job-details p {
    margin-bottom: 10px;
}

.job-details span {
    font-weight: bold;
    color: #1ca32c;
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0%; /* Start at 0% width */
  background-color: #4CAF50;
  transition: width 1s ease-in-out; /* Smooth transition for width change */
}

.loading-bar.loading {
  width: 100%; /* Expand to full width when loading */
}

/* Modern Minimalist Scrollbar Styles */
.job-column::-webkit-scrollbar,
.details-column::-webkit-scrollbar {
  width: 4px; /* Thinner width */
  height: 4px;
}

.job-column::-webkit-scrollbar-thumb,
.details-column::-webkit-scrollbar-thumb {
  background: rgba(28, 163, 44, 0.2); /* Semi-transparent green */
  border-radius: 50px;
}

.job-column::-webkit-scrollbar-track,
.details-column::-webkit-scrollbar-track {
  background: transparent;
  margin: 4px 0;
}

.job-column:hover::-webkit-scrollbar-thumb,
.details-column:hover::-webkit-scrollbar-thumb {
  background: rgba(28, 163, 44, 0.6); /* More visible on container hover */
}

/* Firefox scrollbar styles */
.job-column,
.details-column {
  scrollbar-width: thin;
  scrollbar-color: rgba(28, 163, 44, 0.2) transparent;
}

.job-column:hover,
.details-column:hover {
  scrollbar-color: rgba(28, 163, 44, 0.6) transparent;
}
