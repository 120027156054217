@import url('https://fonts.googleapis.com/css?family=Electrolize');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    min-height: 100vh;
}

body {
    font-family: 'Electrolize', sans-serif; /* Apply Electrolize font to the entire page */
    font-size: 16px;                        /* Set a base font size for readability */
    line-height: 1.6;                       /* Increase line height for better readability */
    background-color: #fff;;
}

/* General styles for the user dashboard */
.user-dashboard {
    font-family: 'Electrolize', sans-serif;
    background-color: #fff; /* Light background for better contrast */
    padding: 20px;
    border-radius: 8px;
    
}

/* Navbar styling */
.dashboard-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1ca32c; /* Green background */
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
}

.dashboard-nav h2 {
    margin: 0;
}

/* Main content area */
.dashboard-main {
    margin-top: 20px;
}

/* File upload section styles */
.file-upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;          /* Existing padding */
    padding-top: 40px;      /* Add extra padding at the top */
}

.file-upload-section input[type="file"] {
    padding: 10px;
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px;
}

.file-upload-section button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #fff; /* Green button */
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s;
}

.file-upload-section button:disabled {
    background-color: #ccc; /* Disabled button style */
    cursor: not-allowed;
}

.file-upload-section button:hover:not(:disabled) {
    background-color: #169c2b; /* Darker green on hover */
}

/* Error message styling */
.error-message {
    color: red;
    margin-top: 10px;
}

/* Profile form styling */
.profile-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white; /* White background for form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.profile-form h3 {
    margin: 0 0 10px;
}

.profile-form h4 {
    margin-top: 10px;
}

.profile-form label {
    font-weight: bold;
}

.profile-form input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Array item styling */
.array-item {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between input and remove button */
}

.array-item button {
    background-color: #fff; /* Red background for remove button */
    color: #000;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.array-item button:hover {
    background-color: #df4742;
    color: #fff; /* Darker red on hover */
}

/* Save profile button */
.profile-form button[type="submit"] {
    background-color: #5bc0de; /* Light blue for save button */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-form button[type="submit"]:hover {
    background-color: #31b0d5; /* Darker blue on hover */
}


/* top header */

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 80px;
  background-color: #fff; /* Light background for the header */
  border-bottom: 2px solid #1ca32c; /* Green border for separation */
}

.dashboard-title {
  font-size: 24px;
  color: #333; /* Dark text color */
}

.space-btn {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1ca32c; /* Green background */
  color: white; /* White text */
  transition: background-color 0.3s ease;
}

.space-btn:hover {
  background-color: #17a34d; /* Darker green on hover */
}

.file-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.fine-input {
    padding-top: 80px; /* Adjust the value as needed */
    padding: 10px; /* Add padding for other sides if needed */
    border-bottom: 2px solid #1ca32c; /* Keep your existing styling */
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
}

.error-message {
  color: #f44336; /* Red color for error messages */
  margin-top: 10px;
}

/* top header end */

@import url('https://fonts.googleapis.com/css?family=Lato:400,500');

.custom-btn {
    width: 130px;
    height: 40px;
    padding: 10px 25px;
    border: 2px solid #1ca32c;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.custom-btn-rem {
    width: 130px;
    height: 40px;
    padding: 10px 25px;
    border: 2px solid #d9534f;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
}

.btn-4 {
    position: relative;
    color: #1ca32c;
    z-index: 2;
    line-height: 40px;
    padding: 0;
}

.btn-4:hover {
    border: none;
    color: #fff;
}

.btn-4:before,
.btn-4:after {
    position: absolute;
    content: "";
    width: 0%;
    height: 0%;
    border: 2px solid;
    z-index: -1;
    transition: all 0.3s ease;
}

.btn-4:before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: #1ca32c;
    border-left-color: #1ca32c;
}

.btn-4:after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #1ca32c;
    border-right-color: #1ca32c;
}

.btn-4:hover:before,
.btn-4:hover:after {
    border-color: #1ca32c;
    height: 100%;
    width: 100%;
}

.btn-4-rem {
    position: relative;
    color: #d9534f;
    z-index: 2;
    line-height: 40px;
    padding: 0;
}

.btn-4-rem:hover {
    border: none;
    color: #fff;
}

.btn-4-rem:before,
.btn-4-rem:after {
    position: absolute;
    content: "";
    width: 0%;
    height: 0%;
    border: 2px solid;
    z-index: -1;
    transition: all 0.3s ease;
}

.btn-4-rem:before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: #d9534f;
    border-left-color: #d9534f;
}

.btn-4-rem:after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #d9534f;
    border-right-color: #d9534f;
}

.btn-4-rem:hover:before,
.btn-4-rem:hover:after {
    border-color: #d9534f;
    height: 100%;
    width: 100%;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

.navbar-title:hover {
  opacity: 0.8;
}

.title-text {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.search-container {
  position: relative;
}

.search-input {
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  width: 200px;
  transition: width 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  width: 250px;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.nav-buttons {
  display: flex;
  gap: 0.75rem;
}



.space-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.space-btn:hover {
  background-color: #e0e0e0;
}

.logout-button {
  background-color: #ff4444;
  color: white;
}

.logout-button:hover {
  background-color: #cc0000;
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.3s;
}

.loading-bar.loading {
  background-color: #007bff;
  animation: loading 1s infinite linear;
}

@keyframes loading {
  0% {
    background: linear-gradient(to right, transparent 0%, #007bff 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: 200% 0;
  }
  100% {
    background: linear-gradient(to right, transparent 0%, #007bff 50%, transparent 100%);
    background-size: 200% 100%;
    background-position: -200% 0;
  }
}

.file-preview-container {
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f5f5f5;
  border-bottom: 1px solid #eee;
}

.preview-header h4 {
  margin: 0;
  color: #333;
}

.toggle-preview-btn {
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  color: #666;
  transition: all 0.2s ease;
}

.toggle-preview-btn:hover {
  background: #f0f0f0;
  border-color: #ccc;
}

.file-preview-content {
  padding: 20px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  background: white;
  white-space: pre-wrap;
  word-wrap: break-word;
  transition: max-height 0.3s ease;
}

.file-preview-content.full {
  max-height: 600px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.file-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Add custom scrollbar styles */
.file-preview-content::-webkit-scrollbar {
  width: 8px;
}

.file-preview-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.file-preview-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.file-preview-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.file-upload-wrapper {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

.file-upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-upload-label:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

.upload-icon {
  width: 48px;
  height: 48px;
  stroke-width: 1.5;
  margin-bottom: 1rem;
  color: #6c757d;
}

.file-upload-label span {
  font-size: 1rem;
  color: #495057;
}

.file-types {
  font-size: 0.875rem;
  color: #6c757d;
  margin-top: 0.5rem;
}

.file-upload-wrapper:focus-within .file-upload-label {
  border-color: #4dabf7;
  outline: none;
  box-shadow: 0 0 0 3px rgba(77, 171, 247, 0.2);
}

.profile-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.file-upload-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.file-upload-wrapper.compact {
  width: auto;
}

.file-upload-label.compact {
  padding: 8px 15px;
  font-size: 14px;
}

.upload-icon.small {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.button-group.compact {
  display: flex;
  gap: 8px;
}

.space-btn.small {
  padding: 8px 15px;
  font-size: 14px;
}
