/* Notification Container */
.notification {
  height: auto; /* Allow height to adjust based on content */
  width: 350px; /* Fixed width for consistency */
  background-color: #74c087; /* Softer green for a calming look */
  position: fixed; /* Ensured it's always in view */
  top: 20px; /* Position from the top */
  right: 20px; /* Position from the right */
  cursor: pointer; /* Change cursor to pointer */
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out content and close button */
  padding: 10px 15px; /* Padding for content spacing */
  gap: 15px; /* Space between elements */
  z-index: 1000; /* Ensure it’s on top of other elements */
  transition: opacity 0.3s ease; /* Fade-in effect */
}

/* Visual Identifier (Icon) */
.identifier {
  height: 24px; /* Height of the identifier */
  width: 24px; /* Width of the identifier */
  border-radius: 50%; /* Make it circular */
  background-color: #1ca32c; /* Identifier color */
  flex-shrink: 0; /* Prevent it from resizing */
}

/* Text Content */
.text {
  font-size: 1rem; /* Font size for the message */
  color: white; /* White text for contrast */
  font-weight: 500; /* Medium weight for text */
  flex-grow: 1; /* Take available space */
  word-wrap: break-word; /* Allows long words to break into the next line */
}

/* Close Button */
.close-button {
  background: none; /* No background */
  border: none; /* No border */
  color: white; /* White color for contrast */
  font-size: 20px; /* Font size for close button */
  cursor: pointer; /* Change cursor to pointer */
  padding: 0 10px; /* Horizontal padding */
}

/* Optional: Adding some responsiveness */
@media (max-width: 400px) {
  .notification {
    width: 90%; /* Makes it responsive on smaller screens */
  }
}
