body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.back-arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 1000;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-arrow:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sign-in-sign-up-grid {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  background-color: #fff;
}

.back-arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #1ca32c;
}

.info-column-wrapper {
  position: relative;
  background-image: url('/public/candidate.jpeg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
}

.info-column {
  position: relative;
  z-index: 1;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.info-column h1 {
  font-size: 5.5rem;
  color: #1ca32c;
  margin-bottom: 1rem;
}

.info-column p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.info-column .main-button {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid white;
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.info-column .main-button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.main-button {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid white;
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.main-button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.form-column {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.form-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 500px;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.auth-form input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Ensures input boxes are the same width */
}

.password-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
}

button {
  background-color: #1ca32c;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.styled-link-btn {
  background: none;
  border: none;
  color: #1ca32c;
  cursor: pointer;
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}

.forgot-password {
  color: #1ca32c;
  text-decoration: none;
  margin-left: auto; /* Pushes the link to the far right */
}
.go-back-btn {
  background-color: #e0e0e0;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.typewriter {
  border-right: 2px solid #fff;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #fff }
}
