.company-selector {
  width: 100%;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-button {
  width: 100%;
  min-height: 48px;
  padding: 12px 16px;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px solid #e1e8ed;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  color: #14171a;
  position: relative;
}

.dropdown-button:hover {
  border-color: #1da1f2;
  background-color: #f8fafc;
}

.dropdown-logo {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 12px;
  object-fit: contain;
}

.selected-company-name {
  flex: 1;
  text-align: left;
  font-weight: 500;
}

.placeholder-text {
  flex: 1;
  text-align: left;
  color: #657786;
}

.dropdown-arrow {
  font-size: 12px;
  color: #657786;
  margin-left: 8px;
  transition: transform 0.2s ease;
}

.dropdown[aria-expanded="true"] .dropdown-arrow {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  z-index: 100;
  background-color: white;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@keyframes dropdownFade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-content::-webkit-scrollbar {
  width: 8px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-item {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f8fafc;
}

.dropdown-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.company-name {
  font-size: 15px;
  color: #14171a;
  font-weight: 400;
}

/* Add subtle hover state for dropdown button */
.dropdown-button:focus {
  outline: none;
  border-color: #1da1f2;
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.2);
}

/* Add a subtle active state */
.dropdown-button:active {
  background-color: #f5f8fa;
}

/* Style for disabled state */
.dropdown-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Loading state for company logos */
.dropdown-logo {
  background-color: #f5f8fa;
  transition: opacity 0.2s ease;
}

.dropdown-logo[src=""] {
  opacity: 0;
}

/* Style for the search input */
.search-input {
  border: none;
  background: transparent;
  padding: 4px 8px;
  width: 100%;
  height: 24px;
  font-size: 14px;
  outline: none;
  color: #333;
}

.search-input::placeholder {
  color: #999;
  font-size: 13px;
}

.search-input:focus {
  background-color: rgba(0, 0, 0, 0.02);
  transition: background-color 0.2s ease;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .dropdown-button {
    min-height: 44px;
    padding: 10px 12px;
  }

  .dropdown-logo {
    width: 20px;
    height: 20px;
  }

  .dropdown-item {
    padding: 10px 12px;
  }
}

.dropdown-button .search-input {
  border: none;
  background: none;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  outline: none;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}