@import url('https://fonts.googleapis.com/css?family=Electrolize');
@import url('https://fonts.googleapis.com/css?family=Lato:400,500');
@import url('https://fonts.googleapis.com/css?family=Roboto:700');

/* General Body Styles */
body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

/* Container for profile */
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}

/* Navbar */
.navbar {
    position: sticky;
    height: 52px;
    background-color: #fff; /* Background color for the navbar */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space items evenly */
    padding: 0 20px; /* Add horizontal padding */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow for the navbar */
}

/* Title styling */

/* Highlight "fujm" text */
.navbar-title span {
    font-size: 20px; /* Keep "fujm" slightly larger for emphasis */
    color: #1ca32c; /* Green color for emphasis */
}

/* Search Container */
.search-container {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Space between elements */
}

/* Search Bar */
.search-bar1 {
    height: 30px; /* Set height to fit navbar */
    padding: 5px; /* Inner padding */
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners */
}

/* Location Input */
.location-input1 {
    height: 30px; /* Set height to fit navbar */
    padding: 5px; /* Inner padding */
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners */
}

/* Button Styles */
.space-btn {
    background-color: #1ca32c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px; /* Adjust padding to fit navbar */
    cursor: pointer;
    font-size: 14px; /* Adjust font size */
    transition: background-color 0.3s ease;
}

.space-btn:hover {
    background-color: #588660; /* Darken on hover */
}

/* User Controls */
.user-controls {
    display: flex;
    gap: 10px; /* Space between buttons */
}

/* Profile Button */
.logout-button {
    background-color: #1ca32c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px; /* Adjust padding to fit navbar */
    cursor: pointer;
    font-size: 14px; /* Adjust font size */
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #588660; /* Darken on hover */
}

/* General Profile Section Styles */
.profile-section {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;  
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
}


/* Profile Header */

/* Ensure the body or container has padding at the top for the fixed header */
.profile-grid {
    display: flex;
    flex-direction: row; /* Layout as per your previous design */
    align-items: flex-start;
    flex-grow: 1;
    width: 100%;
}
/* Styling for the Personal Information section */
.profile-section h2 {
    margin-bottom: 15px; /* Ensures consistent spacing between header and content */
  }
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
    
  .navbar-title {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #333;
  }
  
  .logo-img {
    width: 34px;
    height: 34px;
  }
  
  .fujm-text {
    color: #4338ca;
  }
  
  .header-buttons {
    display: flex;
    gap: 10px;
  }
  
  .header-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: #f0f0f0;
    color: #333;
    transition: all 0.2s ease;
  }
  
  .header-button:hover {
    background-color: #e0e0e0;
  }
  
  .header-button.logout-button {
    background-color: #ff4444;
    color: white;
  }
  
  .header-button.logout-button:hover {
    background-color: #cc0000;
  }
  
  .skills-section {
    display: flex;
    flex-direction: column;
  }
  
  .skill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
  
  .skill p {
    margin: 0; /* Remove default margin for paragraph inside skill */
    flex-grow: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .skill {
      flex-direction: column; /* Stack elements in skill section on smaller screens */
    }
  
    .skill p {
      margin-bottom: 5px; /* Space between skill text and button */
    }
  }
  

/* Profile Grid */
.profile-content {
    flex-grow: 1;
    padding: 15px;
    background-color: #ffffff;
    border-left: 1px solid #fff;
    border-radius: 10px;
    min-width: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* Styling for the Work Experience section */
.profile-section {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-section h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: #1ca32c;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  
  .profile-item {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
  }
  
  .profile-item p {
    margin: 10px 0;
    font-size: 1em;
    display: flex;
    align-items: center;
  }
  
  .profile-item strong {
    color: #555;
    margin-right: 10px;
    min-width: 120px;
  }
  
  /* Input fields in edit mode */
  .profile-item input[type="text"] {
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(100% - 130px);
    transition: border-color 0.2s;
  }
  
  .profile-item input[type="text"]:focus {
    border-color: #1ca32c;
    outline: none;
  }
  
  /* Button styling */
  .custom-btn {
    padding: 10px 15px;
    font-size: 1em;
    color: white;
    background-color: #1ca32c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-btn:hover {
    background-color: #169a28;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .profile-item p {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .profile-item input[type="text"] {
      width: 100%;
      margin-top: 5px;
    }
  }
  
/* Sidebar styling */
.profile-sidebar {
    width: 250px;
    background-color: #fff; /* Ensure the background is white */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
    position: relative;
    margin-top: 20px; /* Adjust this based on your needs */
    flex-shrink: 0; /* Prevent sidebar from shrinking */
    border: 1px solid #e0e0e0; /* Optional: adds a subtle border to the sidebar */
}

/* Tabs container */
.tabs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

/* Tab Button */
.tab-btn {
    padding: 12px 0;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: #666; /* Subtle dark gray */
    cursor: pointer;
    outline: none;
    transition: color 0.3s ease, transform 0.3s ease, border-bottom 0.3s ease;
    position: relative;
    width: 100%;
    letter-spacing: 0.5px;
    text-transform: capitalize;
}

/* Tab Button Hover */
.tab-btn:hover {
    color: #1ca32c; /* Green hover effect */
    background-color: transparent;
    transform: translateX(5px); /* Small slide to the right */
}

/* Active Tab Styling */
.tab-btn.active {
    color: #1ca32c; /* Green active color */
    font-weight: bold; /* Make the active tab bold */
    border-bottom: 2px solid #1ca32c; /* Underline active tab */
}

/* Focus Styling for Accessibility */
.tab-btn:focus {
    outline: none;
    border-bottom: 2px solid #1ca32c; /* Underline when tab is focused */
    color: #1ca32c;
}

/* Responsive Design */
@media (max-width: 768px) {
    .profile-sidebar {
        max-width: 100%; /* Full width on mobile */
        padding: 15px;
    }

    .tab-btn {
        padding: 10px; /* Compact padding for mobile */
        font-size: 0.85rem; /* Adjust font size for mobile */
    }
}
