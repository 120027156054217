/* Reset margins and paddings */
body, html {
    margin: 0;
    padding: 0;
    background-color: white; /* Ensures the background is white */
    font-family: 'Arial', sans-serif;
    height: 100%;
}

/* Main container */
.fujm-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    position: relative;
    z-index: 1;
}

/* Navbar styles */
.navbar1 {
    padding: 1rem; /* Padding for navbar */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0);
}

/* Logo styles */
.logo-2 h3 {
    color: #1ca32c; /* Green color for the main heading */
    font-family: 'Oswald', sans-serif; /* Oswald font for the heading */
    font-weight: 300; /* Light weight */
    font-size: 7em; /* Font size */
    line-height: 1; /* Line height */
}

.logo-2 p {
    font-size: 13px; /* Smaller font size for the paragraph */
    color: #000000; /* Dark grey color for the paragraph */
    font-weight: 600; /* Semi-bold weight */
}

.logo-holder {
    text-align: center; /* Center-align the logo holder */
}

/* Input fields styles */
.filter-container {
    margin: 10px 0;
    width: 100%;
    max-width: 600px;
}


/* Search bar container styles */
.search-bar-container {
    margin: 10px 0;
    width: 100%;
    max-width: 600px;
}

/* Search bar styles */
.search-bar {
    width: 100%;
    padding: 10px 0; /* Vertical padding */
    border: none; /* Remove all borders */
    border-bottom: 2px solid #e2e2e2; /* Add a bottom border */
    font-size: 14px;
    background-color: transparent; /* Transparent background */
    color: #333333; /* Text color */
    transition: border-color 0.3s ease;
}

/* Focus state for search bar */
.search-bar:focus {
    border-bottom: 2px solid #1ca32c; /* Change to green on focus */
    outline: none; /* Remove default focus outline */
}

/* Placeholder styles */
.search-bar::placeholder {
    color: #aaa; /* Light gray for placeholder text */
}
.location-input {
    display: block;
    margin-left: auto;
    margin-right: auto; /* Centers the input box */
    padding: 10px 0; /* Vertical padding to match search bar */
    border: none; /* Remove all borders */
    border-bottom: 2px solid #e2e2e2; /* Add a bottom border */
    width: 40%; /* Maintain width */
    font-size: 14px;
    background-color: transparent; /* Transparent background */
    color: #333; /* Text color */
    transition: border-color 0.3s ease;
}
/* Focus state for location input */
.location-input:focus {
    border-bottom: 2px solid #1ca32c; /* Change to green on focus */
    outline: none; /* Remove default focus outline */
}

/* Placeholder styles */
.location-input::placeholder {
    color: #aaa; /* Light gray for placeholder text */
}
/* Button styles */
.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.search-button, .clear-button, .upload-resume-button {
    padding: 12px 24px;
    font-size: 16px;
    border: 2px solid transparent;
    border-radius: 25px; /* Rounded corners */
    cursor: pointer;
    background-color: transparent; /* Make buttons transparent initially */
    transition: border-color 0.3s ease, color 0.3s ease;
}

.search-button {
    color: #1ca32c;
    border-color: #1ca32c; /* Green border */
}

.search-button:hover {
    background-color: #1ca32c;
    color: white;
}

/* Auth link styles */
.container {
    position: relative !important; /* Required for absolute positioning of child elements */
    height: 100px !important; /* Set a height for the container */
}

.auth-section {
    display: flex; /* Use flexbox for horizontal alignment */
    gap: 10px; /* Space between buttons */
    position: absolute; /* Position the section absolutely */
    top: 20px; /* Align to the top */
    right: 20px; /* Align to the right */
}

.auth-link {
    font-size: 14px !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    color: #007bff !important; /* Blue color for the link */
    cursor: pointer !important;
    padding-bottom: 5px !important; /* Adjust for spacing between text and border */
    position: relative; /* Set relative positioning for pseudo-element */
}

.auth-link::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important; /* Start with no width */
    height: 2px !important; /* Border height */
    background-color: #007bff !important; /* Blue color for the bottom border */
    left: 50% !important; /* Center the border */
    bottom: 0 !important; /* Align the border at the bottom of the link */
    transition: width 0.3s ease, left 0.3s ease !important; /* Smooth transition */
    transform: translateX(-50%); /* Center the border */
}

.auth-link:hover::after {
    width: 100% !important; /* Expand the border width on hover */
    left: 50% !important; /* Maintain centered position */
}

.auth-link1 {
    font-size: 14px !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    color: #007bff !important; /* Blue color for the link */
    cursor: pointer !important;
    padding-bottom: 5px !important; /* Adjust for spacing between text and border */
    position: relative; /* Set relative positioning for pseudo-element */
}

.auth-link1::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important; /* Start with no width */
    height: 2px !important; /* Border height */
    background-color: #007bff !important; /* Blue color for the bottom border */
    left: 50% !important; /* Center the border */
    bottom: 0 !important; /* Align the border at the bottom of the link */
    transition: width 0.3s ease, left 0.3s ease !important; /* Smooth transition */
    transform: translateX(-50%); /* Center the border */
}

.auth-link1:hover::after {
    width: 100% !important; /* Expand the border width on hover */
    left: 50% !important; /* Maintain centered position */
}

.auth-link2 {
    font-size: 14px !important;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    color: #1ca32c !important; /* Blue color for the link */
    cursor: pointer !important;
    padding-bottom: 5px !important; /* Adjust for spacing between text and border */
    position: relative; /* Set relative positioning for pseudo-element */
}

.auth-link2::after {
    content: '' !important;
    position: absolute !important;
    width: 0 !important; /* Start with no width */
    height: 2px !important; /* Border height */
    background-color: #1ca32c !important; /* Blue color for the bottom border */
    left: 50% !important; /* Center the border */
    bottom: 0 !important; /* Align the border at the bottom of the link */
    transition: width 0.3s ease, left 0.3s ease !important; /* Smooth transition */
    transform: translateX(-50%); /* Center the border */
}

.auth-link2:hover::after {
    width: 100% !important; /* Expand the border width on hover */
    left: 50% !important; /* Maintain centered position */
}


.squares-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.made-with-love {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: #718096;
}

.heart-container {
  display: flex;
  align-items: center;
  color: #E53E3E;
  margin: 0 2px;
}

.author-link {
  color: #3182CE;
  text-decoration: none;
  margin-left: 2px;
}

.author-link:hover {
  text-decoration: underline;
}

