.PopupContainer {
  position: relative;
}

.image-upload {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}



.custom-upload {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.custom-upload svg {
  width: 24px;
  height: 24px;
  color: rgb(29, 155, 240);
}

.custom-upload span {
  color: rgb(29, 155, 240);
  font-weight: bold;
  font-size: 16px;
}

.image-upload-label {
  position: relative;
  display: inline-block;
  background-color: #1da1f2;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.image-upload-label:hover {
  background-color: #0d8bd9;
}

.image-upload-label input {
  display: none;
}



.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e5e7eb;
}

.popup-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.close-btn {
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.close-btn:hover {
  background-color: #f3f4f6;
}

.close-btn svg {
  fill: #6b7280;
}

.close-btn:hover svg {
  fill: #374151;
}

.popup-inner-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  height: auto;
  max-height: 500px;
  overflow: hidden;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.popup-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(29, 155, 240);
  color: rgb(255, 255, 255);
  text-decoration: none;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-btn:hover {
  background-color: rgb(26, 140, 220);
}

.popup-btn-content {
  display: flex;
  align-items: center;
}

.popup-icon {
  width: 24px;
  height: 24px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tweet-area {
  position: relative;
  min-height: 130px;
  max-height: 170px;
  overflow-y: auto;
}


/* Editable input styling */
.input.editable {
  outline: none;
  font-size: 16px;
  color: #333; /* Darker color for better visibility */
  min-height: 130px; /* Ensures it doesn't collapse */
  width: 100%;
  background-color: transparent; /* Matches background */
  padding: 5px;
  line-height: 1.5;
}
.post-btn {
  padding: 8px 16px;
  background-color: #1da1f2;
  border: none;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.post-btn:enabled {
  opacity: 1;
}

.post-btn:hover:enabled {
  background-color: #0d8bd9;
}

.PopupContainer {
  position: relative;
}

.image-upload {
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  max-width: 300px;
}

.image-upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1da1f2;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 20px;
  max-height: 24px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.image-upload-label:hover {
  background-color: #0d8bd9;
  transform: scale(1.05);
}

.image-upload-label input {
  display: none;
}

.uploaded-image-container {
  position: relative;
  display: inline-block;
}

.uploaded-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 8px;
}

.remove-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 18px;
  background-color: #fff;
  color: #7a7a7a;
  pointer-events: none;
}

.custom-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.toolbar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.toolbar button {
  font-weight: bold;
  background-color:rgb(116, 230, 154);
  border-color: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.toolbar button:hover {
  background-color: #e0e0e0;
}
/* Add these new styles to your existing Popup.css file */

.company-selector-container {
  margin-bottom: 20px;
  width: 100%;
}

/* Adjust the existing company selector styles to match the popup theme */
.company-selector .dropdown-button {
  background-color: white;
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  text-align: left;
  transition: background-color 0.2s;
}

.company-selector .dropdown-button:hover {
  background-color: #f5f8fa;
}

.company-selector .dropdown-content {
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
}

.company-selector .dropdown-item {
  padding: 10px;
  transition: background-color 0.2s;
}

.company-selector .dropdown-item:hover {
  background-color: #f5f8fa;
}

/* Ensure the dropdown appears above other content */
.company-selector .dropdown-content {
  z-index: 1000;
}

.tweet-popup-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.input-group {
  width: 100%;
}

.job-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.job-input:focus {
  border-color: #1da1f2;
  outline: none;
}

.tweet-area {
  position: relative;
  width: 100%;
  min-height: 150px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
}

.placeholder {
  position: absolute;
  top: 12px;
  left: 16px;
  color: #757575;
  font-size: 14px;
  pointer-events: none;
}

.input.editable {
  width: 100%;
  min-height: 150px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1.5;
  border: none;
  outline: none;
  resize: none;
  overflow-y: auto;
}

.tweet-area:focus-within {
  border-color: #1da1f2;
}

.tweet-area .input.editable {
  overflow-y: auto;
  min-height: 100px;
  max-height: 200px;
}

.processed-output {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.processed-output h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333;
}

.processed-output pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 0.9rem;
  color: #666;
}