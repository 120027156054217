/* Global body styles */
html, body {
  height: 100%; /* Ensure the body takes up the full height */
}

/* Scoped body styles for Wall component */
.wall-active {
  height: 100%; /* Set the height to 100% to fill the entire page */
  background-color: #000;
  color: white;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

/* Overall container for the Wall */
.WallContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  align-items: center; /* Centers all elements horizontally */
  justify-content: flex-start; /* Align content to the top */
  overflow-y: auto; /* Allow scrolling only within the wall container */
  height: calc(100vh - 60px); /* Adjust based on your navbar height */
}

/* Main content section */
.WallGrid {
  display: flex;
  width: 100%; /* Full width for content */
  margin-top: 100px; /* Space to push content below the fixed logo */
  gap: 20px; /* Space between columns */
}

/* Column 1: Logo */
.logo-column {
  flex: 0 0 auto; /* Fixed size */
}

/* Logo Styling */
.WallLogo {
  position: fixed;
  top: 20px; /* Space between the top and the logo */
  left: 20px; /* Space from the left */
  height: 80px;
  width: auto;
  z-index: 10; /* Ensure logo stays above other content */
}

/* Column 2: Tweet Cards */
.content-column {
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width for content */
  padding: 20px;
}

/* Card Container (Grid Layout) */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive min width for cards */
  gap: 20px; /* Space between the cards */
  padding: 16px;
  width: 100%;
  max-width: 1200px; /* Max width to center the content */
  margin: 0 auto; /* Center the grid horizontally */
  overflow: hidden;
}

/* Card Styling */
.tweet-card {
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: 100%; /* Full width within grid */
  height: fit-content;
  max-width: 250px; /* Fixed width for consistent size */
  padding: 15px;
  text-align: left;
  transition: transform 0.3s ease;
}

.tweet-card:hover {
  transform: translateY(-5px); /* Subtle hover effect */
}

/* Content within the Tweet Card */
.card-content {
  padding: 10px 0;
}

.tweet-text {
  font-size: 14px;
  color: #e0e0e0;
  margin-bottom: 12px;
}

.tweet-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 10px;
}

.likes-section {
  display: flex;
  align-items: center;
  color: #00d1b2;
  margin-top: 8px;
}

.icon {
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #888;
}

.icon.liked {
  color: red; /* Like icon color when liked */
}

.likes {
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
}

.timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 10px;
}

/* Like Button Styling */
.like-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
}

/* Responsive Styling */
@media (max-width: 600px) {
  .tweet-card {
    max-width: 100%; /* Ensure cards take full width on small screens */
  }
}
/* Overlay styles */
.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it overlays on top of everything else */
}

.overlay-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  cursor: pointer;
}

/* Optional: Add some smooth transition when showing/hiding the overlay */
.image-overlay {
  transition: opacity 0.3s ease;
}
/* Sorting Filter */
.sort-filter {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1); /* Slight transparent background */
  padding: 8px 15px;
  border-radius: 30px; /* Rounded container */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  backdrop-filter: blur(5px); /* Subtle blur effect */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effects */
}

.sort-filter:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  background-color: rgba(255, 255, 255, 0.2); /* Slightly brighter on hover */
}

/* Sort label styling */
.sort-label {
  font-size: 14px;
  font-weight: 500;
  color: #00d1b2; /* Light gray for modern look */
  margin-right: 10px;
  letter-spacing: 0.5px; /* Slight spacing for elegance */
}

/* Select dropdown styling */
.sort-select {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600; /* Bold text for modern touch */
  color: #fff;
  background-color: #2a2a2a; /* Dark background for select box */
  border: none;
  border-radius: 25px; /* Round the corners for modern style */
  appearance: none; /* Remove default dropdown arrow */
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.sort-select:hover {
  background-color: #00d1b2; /* Change background color on hover */
}

.sort-select:focus {
  background-color: #00b09b; /* Slightly darker when focused */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Shadow effect when focused */
}

.sort-select option {
  background-color: #2a2a2a; /* Keep select options dark */
  color: #fff;
}

/* Custom dropdown arrow */
.sort-select::-ms-expand {
  display: none;
}

/* Navbar styles */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
}

.nav-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.space-btn {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logout-button {
  background-color: #f0f0f0;
  color: #333;
}

.logout-button:hover {
  background-color: #e0e0e0;
}

.signin-signup-button {
  background-color: #1ca32c;
  color: white;
}

.signin-signup-button:hover {
  background-color: #158f25;
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.3s;
}

.loading-bar.loading {
  background-color: #1ca32c;
}

/* Wall content styles */
.wall-content {
  padding: 2rem;
  /* Add your wall-specific styles here */
}

.space-btn .material-icons {
  font-size: 30px;
  color: #333;
}

.space-btn:hover .material-icons {
  color: #bebebe;
}

.popup-container {
  position: relative;
  z-index: 1000;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.navbar {
  position: relative;
  z-index: 998;
}

.search-input {
  padding: 12px 20px;  /* Increased padding */
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  width: 200px;
  font-size: 14px;
  height: 35px;  /* Added explicit height */
  box-sizing: border-box;  /* Added to ensure padding doesn't affect overall size */
}

.search-input:focus {
  outline: none;
  border-color: #1ca32c;
  }

.job-list-page {
  overflow: hidden;  /* Prevent scrolling on the main container */
  height: 100vh;     /* Full viewport height */
}
